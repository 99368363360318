import { useEffect, useState } from 'react'

const data = require('./data.json')
const _ = require('lodash')

export default function Result(props) {

    let { volume, industry, transaction, show } = props

    let groupedTurnover = _.groupBy(data, 'turnover')

    const numericValues = {
        '£0 - £100,000': 100000,
        '£100,001 - £250,000': 250000,
        '£250,001 - £500,000': 500000,
        '£500,001 - £1,000,000': 1000000,
        '£1,000,001 - £5,000,000': 5000000,
        '£5,000,001 - £10,000,000': 10000000,
        '£10,000,001 - £50,000,000': 50000000,
    };


    const sortedTurnover = _.orderBy(
        groupedTurnover,
        [(turnover) => numericValues[turnover[0].turnover]],
        ['asc']
    );


    const keysArray = Object.keys(sortedTurnover);

    const [sel, setSel] = useState({})
    const [amount, setAmount] = useState(10)

    useEffect(() => {
        if (volume && transaction) {

            let ob = sortedTurnover[keysArray[volume - 1]]

            const selectedData = _.filter(ob, { 'ATV': transaction });

            console.log('Selected', selectedData[0])

            setSel(selectedData[0])

        }

    }, [volume, industry, transaction])

    return (
        <section className="psrRates psr_wrap">
            <div className="component-container  ">
                <div className="component-offset">
                    <div className="gc-1-lg gc-1-md gc-1-sm">
                        <div className="psr_main">
                            <div className="psr_summary">
                                <div className="psr_summay_sec">
                                    <div className="psr_summary_img">
                                        <img alt="logo" src="https://psrcareers.resourcesolutions.com/gold/iapply/includes/branding/184-psr/images/psr-logo.png" />
                                    </div>
                                </div>
                                <div className="body">
                                    <p>
                                        This summary shows our charges for card payments, and other important information about our service.You can use this to compare our service with other providers, to find the best deal for you. We are required
                                        to provide this information by the Payment Systems Regulator.
                                    </p>
                                </div>
                            </div>


                            <div className="psr_cards">
                                <div className="psr_cards_header">
                                    <div className="body">
                                        <h4>Your costs for accepting common individual card payments<sup>4</sup></h4>
                                    </div>

                                </div>
                                <div className="mob_bracket_area">
                                    <span className="mob_bracket" aria-hidden="true"></span>
                                    <div className="psr_card_details">
                                        <div className="psr_mastercard">
                                            <div className="psr_mastercard_table">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td colSpan="3"><div className="psr_cards_title">Mastercard</div></td>
                                                        </tr>

                                                        <tr>
                                                            <td><p>UK-based card</p></td>
                                                            <td>
                                                                <p>Debit<sup>1</sup></p>
                                                            </td>
                                                            <td>
                                                                <p>Credit<sup>2</sup></p>
                                                            </td>
                                                            <td>
                                                                <p>Business<sup>3</sup></p>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                In person
                                                            </td>
                                                            <td>{sel?.mastercardDebit}</td>
                                                            <td>{sel?.mastercardCredit}</td>
                                                            <td>{sel?.mastercardBusiness}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Online/phone
                                                            </td>
                                                            <td>{(parseFloat(sel?.mastercardDebit) + 0.4).toFixed(2)}%</td>
                                                            <td>{(parseFloat(sel?.mastercardCredit) + 0.4).toFixed(2)}%</td>
                                                            <td>{(parseFloat(sel?.mastercardBusiness) + 0.4).toFixed(2)}%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="psr_visa">
                                            <div className="psr_visa_table">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td colSpan="3"><div className="psr_cards_title">Visa</div></td>
                                                        </tr>

                                                        <tr>
                                                            <td><p>UK-based card</p></td>
                                                            <td>
                                                                <p>Debit<sup>1</sup></p>
                                                            </td>
                                                            <td>
                                                                <p>Credit<sup>2</sup></p>
                                                            </td>
                                                            <td>
                                                                <p>Business<sup>3</sup></p>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p>In person<sup>5</sup></p>
                                                            </td>
                                                            <td>{sel?.visaDebit}</td>
                                                            <td>{sel?.visaCredit}</td>
                                                            <td>{sel?.visaBusiness}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p>Online/phone<sup>6</sup></p>
                                                            </td>
                                                            <td>{(parseFloat(sel?.visaDebit) + 0.4).toFixed(2)}%</td>
                                                            <td>{(parseFloat(sel?.visaCredit) + 0.4).toFixed(2)}%</td>
                                                            <td>{(parseFloat(sel?.visaBusiness) + 0.4).toFixed(2)}%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="psr_additional">
                                            <div className="psr_additional_table">
                                                <div className="psr_additional_title">Additional charges per transaction</div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Authorisation fee</td>
                                                            <td>{sel?.authFee}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psr_transac">
                                        <div className="psr_transac_title">Other potential transaction charges</div>
                                        <div className="psr_transac_back">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Chargeback fee</td>
                                                        <td>{sel?.chargebackFee}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="psr_transac_revival">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Revival fee</td>
                                                        <td>{sel?.revivalFee}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="psr_ex">
                                <div className="psr_ex_title">
                                    <h2>Example</h2>
                                </div>
                                <div className="psr_ex_table">
                                    <div>
                                        <div className="only_mob">
                                            <p>If your customer uses a Mastercard&nbsp;<b>personal debit card in person&nbsp;</b>to purchase a £10 item, you would pay:</p>
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>If your customer uses a Mastercard&nbsp;<b>personal debit card in person&nbsp;</b>to purchase a £10 item, you would pay:</p>
                                                    </td>
                                                    <td>
                                                        <div className="person">£0.09</div>
                                                        <div className="fee">(0.9% of £10.00)</div>
                                                    </td>
                                                    <td>
                                                        <div className="person">+ £0.04</div>
                                                        <div className="fee">(authorisation fee)</div>
                                                    </td>
                                                    <td><div className="person">= £0.13</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <div className="only_mob">
                                            <p>If your customer uses a VISA&nbsp;<b>personal credit card online or by phone</b>&nbsp;to purchase a £10 item, you would pay:</p>
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>If your customer uses a VISA&nbsp;<b>personal credit card online or by phone</b>&nbsp;to purchase a £10 item, you would pay:</p>
                                                    </td>
                                                    <td>
                                                        <div className="person">£0.10</div>
                                                        <div className="fee">(1.0% of £10.00)</div>
                                                    </td>
                                                    <td>
                                                        <div className="person">+ £0.04</div>
                                                        <div className="fee">(authorisation fee)</div>
                                                    </td>
                                                    <td><div className="person">= £0.14</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="psr_other">
                                <div className="psr_other_tables">
                                    <div className="psr_other_table">
                                        <div className="psr_other_title">
                                            <h2>Other monthly charges</h2>
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><p>Point-of-sale terminal (per terminal per month)</p></td>
                                                    <td>{sel?.terminal}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p>Gateway<sup>7</sup></p>
                                                    </td>
                                                    <td>{sel?.gateway7}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p>PCI DSS service fee<sup>8</sup></p>
                                                    </td>
                                                    <td>{sel?.fee8}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p>Minimum monthly service charge (MMSC)<sup>9</sup></p>
                                                    </td>
                                                    <td>{sel?.MMSC}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="psr_other_table">
                                        <div className="psr_other_title">
                                            <h2>Other details</h2>
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><p>Settlement time</p></td>
                                                    <td>{sel?.time}</td>
                                                </tr>

                                                <tr>
                                                    <td><p>Payment brands accepted</p></td>
                                                    <td>
                                                        VISA<br />
                                                        Mastercard<br />
                                                        Union Pay<br />
                                                        JCB<br />
                                                        DCI/Discover<br />
                                                        American Express
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="psr_other_services">
                                <div className="psr_service_list">
                                    <ol>
                                        <li>Personal debit cards.</li>
                                        <li>Personal credit cards.</li>
                                        <li>Business debit cards.</li>
                                        <li>
                                            These are the most commonly used cards at most merchants. You pay us a percentage of each card payment you accept. The fee varies depending on the card type. You may also accept other card brand and
                                            types, which may cost more. You can see the cards you accept in the Card Fees section below.
                                        </li>
                                        <li>A card payment where the card, cardholder and merchant are all in the same place and the transaction is contactless or CHIP &amp; PIN.</li>
                                        <li>A card payment where the card, cardholder and merchant are not all in the same place and the transaction is authenticated, i.e. by 3D Secure.</li>
                                        <li>A service for capturing and transferring payment data.</li>
                                        <li>Charge relating to your compliance with card security and anti-fraud standards.</li>
                                        <li>The minimum monthly amount you pay for transaction charges.</li>
                                    </ol>
                                </div>
                            </div>
                            <br /> <br />
                            <div className="qt_submit">
                                <div className="qt_but_left_spacer" aria-hidden="true"></div>

                                <button className="qt_submit_but" id="see-quote"

                                    onClick={() => show(false)}
                                >
                                    Generate new qoute.
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )


}