import { useEffect, useState } from "react";
import Result from "./RResult";

function App() {
  const [volume, setVolume] = useState(null)
  const [transaction, setTransaction] = useState(null)
  const [industry, setIndustry] = useState("")
  const [sis, setSis] = useState(true)
  const [show, setShow] = useState(false)

  const handleVolumeChange = (event) => {
    console.log('volume ', event.target.value)
    setVolume(Number(event.target.value));
  };

  const handleTChange = (event) => {
    console.log('transaction ', event.target.value)
    setTransaction(event.target.value);
  };

  const handleIChange = (event) => {
    console.log('industry ', event.target.value)
    setIndustry(event.target.value);
  };

  useEffect(() => {

    if (volume && transaction && industry)
      setSis(false)

    else setSis(true)

  }, [transaction, industry, volume])

  return (
    <div className="epay">
      <section className="quotationTool">
        {!show &&
          <div className="component-container  vpad-top-32">
            <div className="component-offset">
              <div className="gc-1-lg gc-1-md gc-1-sm">
                <div className="qt_wrap">
                  <div className="qt_sec">
                    <div className="qt_qs>">
                      <div className="qt_q_sec ">
                        <ol className="qt_all_q">
                          <li className="qt_volume">
                            <span className="qt_q"> What&#39;s your annual card volume in GBP (you can approximate)? <br /> </span>
                            <span className="qt_all_a">
                              <ul>

                                <li>
                                  <span
                                    className={`qt_a ${volume === 1 ? 'selected' : 'not-selected'}`}
                                  >
                                    <input
                                      type="radio"
                                      name="annual_volume"
                                      value={1}
                                      checked={volume === 1}
                                      onChange={handleVolumeChange}

                                    /> £0 - £100,000 </span>
                                </li>

                                <li>
                                  <span
                                    className={`qt_a ${volume === 2 ? 'selected' : ''}`}
                                  >
                                    <input type="radio" name="annual_volume"
                                      value={2}
                                      checked={volume === 2}
                                      onChange={handleVolumeChange}
                                    /> £100,001 - £250,000 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${volume === 3 ? 'selected' : ''}`}>
                                    <input type="radio" name="annual_volume"
                                      value={3}
                                      checked={volume === 3}
                                      onChange={handleVolumeChange}
                                    /> £250,001 - £500,000 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${volume === 4 ? 'selected' : ''}`}>
                                    <input type="radio" name="annual_volume"
                                      value={4}
                                      checked={volume === 4}
                                      onChange={handleVolumeChange} /> £500,001 - £1,000,000 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${volume === 5 ? 'selected' : ''}`}

                                  >
                                    <input type="radio" name="annual_volume" value={5}
                                      checked={volume === 5}
                                      onChange={handleVolumeChange} /> £1,000,001 - £5,000,000 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${volume === 6 ? 'selected' : ''}`}>
                                    <input type="radio" name="annual_volume" value={6}
                                      checked={volume === 6}
                                      onChange={handleVolumeChange} /> £5,000,001 - £10,000,000 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${volume === 7 ? 'selected' : ''}`}>
                                    <input type="radio" name="annual_volume" value={7}
                                      checked={volume === 7}
                                      onChange={handleVolumeChange} /> £10,000,001 - £50,000,000 </span>
                                </li>

                              </ul>
                            </span>
                          </li>
                          <li className="qt_avt">
                            <span className="qt_q"> What&#39;s your average business transaction value (again, approximation is fine)? <br /> </span>
                            <span className="qt_all_a">
                              <ul>

                                <li>
                                  <span className={`qt_a ${transaction === '< £5' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value="< £5"
                                      checked={transaction === '< £5'}
                                      onChange={handleTChange}
                                    /> &lt; £5 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£5 - £10' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value="£5 - £10"
                                      checked={transaction === '£5 - £10'}
                                      onChange={handleTChange}
                                    /> £5 - £10 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£11 - £20' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£11 - £20'
                                      checked={transaction === '£11 - £20'}
                                      onChange={handleTChange}
                                    /> £11 - £20 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£21 - £30' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£21 - £30'
                                      checked={transaction === '£21 - £30'}
                                      onChange={handleTChange}
                                    /> £21 - £30 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£31 - £40' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£31 - £40'
                                      checked={transaction === '£31 - £40'}
                                      onChange={handleTChange}
                                    /> £31 - £40 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£41 - £50' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£41 - £50'
                                      checked={transaction === '£41 - £50'}
                                      onChange={handleTChange}
                                    /> £41 - £50 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£51 - £100' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£51 - £100'
                                      checked={transaction === '£51 - £100'}
                                      onChange={handleTChange}
                                    /> £51 - £100 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '£101 - £250' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='£101 - £250'
                                      checked={transaction === '£101 - £250'}
                                      onChange={handleTChange}
                                    /> £101 - £250 </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${transaction === '> 250' ? 'selected' : ''}`}>
                                    <input type="radio" name="average_transaction_volume"
                                      value='> 250'
                                      checked={transaction === '> 250'}
                                      onChange={handleTChange} /> &gt; 250 </span>
                                </li>

                              </ul>
                            </span>
                          </li>
                          <li className="qt_industry">
                            <span className="qt_q"> What&#39;s your industry? <br /> </span>
                            <span className="qt_all_a">
                              <ul>

                                <li>
                                  <span className={`qt_a ${industry === 'retail' ? 'selected' : ''}`}>
                                    <input type="radio" name="mcc_details"
                                      value='retail'
                                      checked={industry === 'retail'}
                                      onChange={handleIChange}
                                    /> Retail </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${industry === 'restaurants' ? 'selected' : ''}`}>
                                    <input type="radio" name="mcc_details"
                                      value='restaurants'
                                      checked={industry === 'restaurants'}
                                      onChange={handleIChange}
                                    /> Restaurants </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${industry === 'travel' ? 'selected' : ''}`}>
                                    <input type="radio" name="mcc_details"
                                      value='travel'
                                      checked={industry === 'travel'}
                                      onChange={handleIChange}
                                    /> Travel &amp; Hospitality </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${industry === 'transport' ? 'selected' : ''}`}>
                                    <input type="radio" name="mcc_details"
                                      value='transport'
                                      checked={industry === 'transport'}
                                      onChange={handleIChange}
                                    /> Transport </span>
                                </li>

                                <li>
                                  <span className={`qt_a ${industry === 'other' ? 'selected' : ''}`}>
                                    <input type="radio" name="mcc_details" value='other'
                                      checked={industry === 'other'}
                                      onChange={handleIChange}
                                    /> Other </span>
                                </li>

                              </ul>
                            </span>
                          </li>
                        </ol>
                        <div className="qt_submit">
                          <div className="qt_but_left_spacer" aria-hidden="true"></div>

                          <button className="qt_submit_but" id="see-quote"
                            disabled={sis}
                            onClick={() => setShow(!show)}
                          >
                            See my quote
                          </button>


                          <div className="qt_but_right_spacer" aria-hidden="true"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {show &&
          <Result volume={volume} industry={industry} transaction={transaction} show={setShow} />
        }

      </section>



    </div>
  );
}

export default App;
